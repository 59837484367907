import React from "react"
// import Img from "gatsby-image"

import style from "./index.module.css";

export default class PosterPoster extends React.Component {
  render() {
    const content = this.props.content
    return (
      <div className={style.container}>
        <div className={style.imageContainer}>
          <img className={style.image} src={content.poster1.sizes.src} alt={content.title} />
        </div>
        <div className={style.imageContainer}>
          <img className={style.image} src={content.poster2.sizes.src} alt={content.title} />
        </div>
      </div>
    )
  }
}