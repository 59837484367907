import React from "react"
// import Img from "gatsby-image"

import style from "./index.module.css";

export default class PosterQuote extends React.Component {
  render() {
    const content = this.props.content
    return (
      <div className={style.container}>
        <div className={style.imageContainer}>
          <img className={style.image} src={content.poster.sizes.src} alt={content.title} />
        </div>
        <div className={style.quoteContainer}>
          <div className={style.quote}>{content.quote.quote}</div>
          <div className={style.quoteAuthor}>- {content.quoteAuthor}</div>
        </div>
      </div>
    )
  }
}