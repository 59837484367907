import React from "react"
import ReactMarkdown from 'react-markdown'
import breaks from 'remark-breaks';

export default class Markdown extends React.Component {
  render() {
    return (
      <ReactMarkdown {... this.props} plugins={[breaks]} renderers={{ Link: props => <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a> }} />
    )
  }
}