import React from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import Img from "gatsby-image"
import Markdown from "../Markdown"
import Content from "../Content"
import Media from "../Content/Media"
import * as PropTypes from "prop-types"
import style from "./index.module.css";

const propTypes = {
  data: PropTypes.object.isRequired,
}

class Project extends React.Component {
  render() {
    const project = this.props.data.projectContent
    const {
      name,
      metaDescription,
      description,
      disciplines,
      subtitle,
      headerImageVideo,
      content,
      link,
      linkTitle,
    } = project
    const video = true;
    return (
      <article>
        <Helmet>
          <title>{name}  - Strawberry Earth</title>
          {metaDescription &&
            <meta name="description" content={metaDescription} />
          }
        </Helmet>

        <div className={style.header}>
          {headerImageVideo && headerImageVideo.map((item, index) => {
            return (
              <div key={index}>
                {"ContentfulContentImage" === item.__typename &&
                  <div>
                    <div className={style.headerContent}>
                      <h1 className={style.title}>{name}</h1>
                    </div>
                    <Img className={style.image} sizes={item.image.sizes} />
                  </div>
                }
                {"ContentfulContentVideo" === item.__typename &&
                  <div className={style.videoContainer}>
                    {"Direct-link" === item.type &&
                      <video className={style.video} src={content.videoUrl} autoPlay loop muted />
                    }
                    {"Youtube" === item.type &&
                      <Media content={item} media={{ image: true, title: true }} hideTopMargin={true} />
                    }
                    {"Vimeo" === item.type &&
                      <Media content={item} media={{ image: true, title: true }} hideTopMargin={true} />
                    }
                  </div>
                }
                <div className={style.details}>
                    <div>{disciplines.disciplines}</div>
                    {" " !== link &&
                      <a className={style.detailsLink} href={link} target="_blank" rel="noopener noreferrer">{linkTitle}</a>
                    }
                </div>
              </div>
            )
          })}
        </div>

        <div className={style.content}>
          {subtitle &&
            <div className={style.subtitle}>{subtitle}</div>
          }
          {description &&
            <Markdown className={style.description} source={description.description} />
          }

        </div>

        {content &&
          <Content content={content} />
        }

        <div>
          <Link className={style.back} to="/werk/">Terug naar overzicht werk</Link>
        </div>

      </article>
    )
  }
}

Project.propTypes = propTypes

export default Project

export const pageQuery = graphql`
  query projectQuery($slug: String) {
    projectContent: contentfulProject(slug: {eq: $slug}) {
      slug
      id
      name
      metaDescription
      link
      linkTitle
      disciplines {
        disciplines
      }
      description {
        description
      }
      subtitle
      headerImageVideo {
        ... on ContentfulContentImage {
          __typename
          id
          title
          caption
          image {
            sizes(maxWidth: 3500, quality: 70) {
              sizes
              src
              srcSet
              srcWebp
              srcSetWebp
            }
          }
        }
        ... on ContentfulContentVideo {
          __typename
          id
          title
          videoUrl
          type
          image {
            sizes(maxWidth: 3500, quality: 70) {
              sizes
              src
              srcSet
              srcWebp
              srcSetWebp
            }
          }
        }
      }
      content {
        ... on ContentfulContentText {
          __typename
          id
          title
          text {
            text
          }
        }
        ... on ContentfulContentImageGrid {
          __typename
          id
          title
          showTitle
          images {
            id
            size
            image {
              title
              sizes(maxWidth: 2500 quality:70) {
                sizes
                src
                srcSet
                srcWebp
                srcSetWebp
              }
            }
          }
        }
        ... on ContentfulContentVideo {
          __typename
          id
          title
          videoUrl
          type
          image {
            sizes(maxWidth: 3500 quality:70) {
              sizes
              src
              srcSet
              srcWebp
              srcSetWebp
            }
          }
        }
        ... on ContentfulContentImage {
          __typename
          id
          title
          caption
          image {
            sizes(maxWidth: 3500 quality:70) {
              sizes
              src
              srcSet
              srcWebp
              srcSetWebp
            }
          }
        }
        ... on ContentfulContentPosterQuote {
          __typename
          id
          title
          quote {
            quote
          }
          quoteAuthor
          poster {
            sizes(maxWidth: 1600 quality:70) {
              sizes
              src
              srcSet
              srcWebp
              srcSetWebp
            }
          }
        }
        ... on ContentfulContentPosterPoster {
          __typename
          id
          title
          poster1 {
            sizes(maxWidth: 1600 quality:70) {
              sizes
              src
              srcSet
              srcWebp
              srcSetWebp
            }
          }
          poster2 {
            sizes(maxWidth: 1600 quality:70) {
              sizes
              src
              srcSet
              srcWebp
              srcSetWebp
            }
          }
        }
      }
    }
  }
`