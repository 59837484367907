import React from "react"
import Text from "./Text"
import Image from "./Image"
import ImageGrid from "./ImageGrid"
import Media from "./Media"
import PosterPoster from "./PosterPoster"
import PosterQuote from "./PosterQuote"

// import style from "./index.module.css";

export default class Content extends React.Component {
  render() {
    const content = this.props.content
    return (
      <div>
        {content && content.map((item, index) => {
          return (
            <div key={index}>
              {"ContentfulContentText" === item.__typename &&
                <Text content={item} />
              }
              {"ContentfulContentImage" === item.__typename &&
                <Image content={item} />
              }
              {"ContentfulContentImageGrid" === item.__typename &&
                <ImageGrid content={item} />
              }
              {"ContentfulContentVideo" === item.__typename &&
                <Media content={item} media={{image: true}} />
              }
              {"ContentfulContentPosterPoster" === item.__typename &&
                <PosterPoster content={item} />
              }
              {"ContentfulContentPosterQuote" === item.__typename &&
                <PosterQuote content={item} />
              }
            </div>
          )
        })}
      </div>
    )
  }
}