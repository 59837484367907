import React from "react"
import Img from "gatsby-image"

import style from "./index.module.css";

export default class ImageGrid extends React.Component {
  render() {
    const content = this.props.content
    return (
      <div className={style.container}>
        {content.showTitle &&
          <h2 className={style.title}>{content.title}</h2>
        }
        <div className={style.list}>
          {content && content.images.map((item, index) => {
            const itemSize = "Large" === item.size ? style.itemWide : style.item;
            return(
              <div className={itemSize} key={index}>
                {item.image &&
                  <Img className={style.image} sizes={item.image.sizes} alt={item.image.title} />
                }
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}