import React from "react"
import Markdown from "../../Markdown"

import style from "./index.module.css";

export default class Text extends React.Component {
  render() {
    const content = this.props.content
    return (
      <div className={style.container}>
        <div className={style.content}>
          <h2 className={style.title}>{content.title}</h2>
          {content.text.text &&
            <Markdown source={content.text.text} />
          }
        </div>
      </div>
    )
  }
}