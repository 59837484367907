import * as React from "react";
import Img from "gatsby-image";
import IconPlay from "./icon-play.svg";

import style from "./index.module.css";

export default class Media extends React.Component {
  constructor(props) {
    super(props)
    this.handleMediaToggle = this.handleMediaToggle.bind(this);
    this.state = {
      showImage: props.media.image ? true : false,
      showMedia: props.media.image ? false : true,
      autoPlay: props.media.autoPlay ? false : true,
      title: props.media.title ? true : false,
    };
  }

  extractYoutubeVideoID(url) {
    // eslint-disable-next-line
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[7].length === 11) {
      return match[7];
    }
  }

  extractVimeoVideoID(url) {
    // eslint-disable-next-line
    const regExp = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
    const match = url.match(regExp);
    if (match) {
      return match[3];
    }
  }

  handleMediaToggle(event) {
    this.setState({
      showMedia: true,
      showImage: false,
      autoPlay: true,
    });
  }

  render() {
    const content = this.props.content
    const hideTopMargin = this.props.hideTopMargin
    return (
      <div className={hideTopMargin ? style.containerAlt : style.container}>
        {"Youtube" === content.type &&
          <div className={style.videoContainer} itemProp="video">
            {this.state.showImage &&
              <div className={style.videoWrapper} onClick={this.handleMediaToggle}>
                <div className={style.content}>
                  {this.state.title &&
                    <h1 className={style.title}>{content.title}</h1>
                  }
                  <img className={style.videoPlayButton} src={IconPlay} alt="Play" />
                </div>
                <Img className={style.image} sizes={content.image.sizes} alt={content.title} />
              </div>
            }
            {this.state.showMedia &&
              <div className={style.videoWrapper}>
                <iframe
                  title="Video"
                  width="560"
                  height="315"
                  // tslint:disable-next-line:max-line-length
                  src={`//www.youtube-nocookie.com/embed/${this.extractYoutubeVideoID(content.videoUrl)}?html5=1&autoplay=${this.state.autoPlay ? 1 : 0}&wmode=transparent&controls=0&modestbranding=1&rel=0&showinfo=0&autohide=1&color=white&iv_load_policy=3&theme=light&playsinline=0`}
                  frameBorder="0"
                  allowFullScreen>
                </iframe>
              </div>
            }
          </div>
        }
        {"Vimeo" === content.type &&
          <div className={style.videoContainer} itemProp="video">
            {this.state.showImage &&
              <div className={style.videoWrapper} onClick={this.handleMediaToggle}>
                <div className={style.content}>
                  {this.state.title &&
                    <h1 className={style.title}>{content.title}</h1>
                  }
                  <img className={style.videoPlayButton} src={IconPlay} alt="Play" />
                </div>
                <Img className={style.image} sizes={content.image.sizes} alt={content.title} />
              </div>
            }
            {this.state.showMedia &&
              <div className={style.videoWrapper}>
                <iframe
                  title="Video"
                  width="560"
                  height="315"
                  // tslint:disable-next-line:max-line-length
                  src={`https://player.vimeo.com/video/${this.extractVimeoVideoID(content.videoUrl)}?autoplay=${this.state.autoPlay ? 1 : 0}&title=0&byline=0&portrait=0`}
                  frameBorder="0"
                  allowfullscreen>
                </iframe>
              </div>
            }
          </div>
        }
        {content.caption &&
          <div className={style.caption}>{content.caption}</div>
        }
      </div>
    );
  }
}
