import React from "react"
import Img from "gatsby-image"

import style from "./index.module.css";

export default class Image extends React.Component {
  render() {
    const content = this.props.content
    return (
      <div className={style.container}>
        <Img className={style.image} sizes={content.image.sizes} alt={content.title} />
        {content.caption &&
          <div className={style.caption}>{content.caption}</div>
        }
      </div>
    )
  }
}